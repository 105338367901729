import React, { Component } from 'react';
import Accent from '../../../Accent';
import { img } from '../../../../lib/brand';
import styles from './styles.module.less';

var allAboutBE = img('AllAboutBE.png', 'WhatInterestsYou');
var firstTime = img('FirstTimeHomebuyer.png', 'WhatInterestsYou');
var currentHome = img('CurrentHomeowner.png', 'WhatInterestsYou');
var diy = img('DIYProjects.png', 'WhatInterestsYou');
var resources = img('Resources.png', 'WhatInterestsYou');
var beyondBE = img('BeyondBE.png', 'WhatInterestsYou');

class WhatInterestsYou extends Component {
   renderLinks(tag, index) {
      return (
         <div key={index} className={styles.linkBlock}>
            <a href={`/bay-equity-news/${tag.name.trim().replace(/ /g, '-').toLowerCase()}`}>
               <div className={styles.tagName}>{tag.name}</div>
               <img src={tag.img} alt={tag.name} />
            </a>
         </div>
      );
   }
   render() {
      let tags = [
         {
            name: 'All About BE',
            img: allAboutBE
         },
         {
            name: 'First-Time Homebuyer',
            img: firstTime
         },
         {
            name: 'Current Homeowner',
            img: currentHome
         },
         {
            name: 'DIY Projects',
            img: diy
         },
         {
            name: 'Resources',
            img: resources
         },
         {
            name: 'Beyond BE',
            img: beyondBE
         }
      ];

      if (this.props.tags) {
         tags = this.props.tags;
      }
      return (
         <section className={styles.container}>
            <h1 className={styles.title}>
               What interests you?
               <Accent align="left" />
            </h1>

            <div className={styles.linkContainer}>{tags.map((tag, i) => this.renderLinks(tag, i))}</div>
         </section>
      );
   }
}
export default WhatInterestsYou;
