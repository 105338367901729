import React, { Component } from 'react';

import IntroHeader from './../IntroHeader';
import FeaturedBlogs from './components/FeaturedBlogs';
import WhatsNew from './components/WhatsNew';
import WhatInterestsYou from './components/WhatInterestsYou';
import { isBe, imgBrand, alt } from './../../lib/brand';

var beBug = imgBrand(isBe ? 'be-circle-mark.png' : 'rf-circle-mark.png');

class BayEquityNews extends Component {
   render() {
      const { corporateBlogs } = this.props;
      let featuredBlogs = corporateBlogs.filter(blog => blog.feature === true).slice(0, 6);

      let introHeader = 'Bay Equity News';
      let par =
         "We're committed to connecting with our clients. Whether it's a conversation in person, a quick call or text during your lunch break or by sharing valuable news you can browse on your own time, we want you to have full access to our expertise.";

      return (
         <section>
            <IntroHeader
               header={introHeader}
               par={par}
               img={beBug}
               alt={alt('The Bay Equity Home Loans Bug - BE Bug Icon')}
            />
            <FeaturedBlogs blogs={featuredBlogs} />
            <WhatsNew blogs={corporateBlogs} />
            <WhatInterestsYou />
         </section>
      );
   }
}

export default BayEquityNews;
